
/* MULTIPLE SELECT BOX */
.kn-selection_status__clear_all___3gppf {
  color: #3f51b5
}
/* GLOBAL STYLES */
#app {
  position: absolute;
  top: 0; bottom: 0; right: 0; left: 0;
}
body {
  font-family: 'PT Sans', sans-serif;
  background: #ebebeb;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}
h1, h3 { font-weight: 300; }
h1 { color: #fff; }
form {      padding: 30px;    padding-top: 60px;    background: #fff;}
.group {   position: relative;  margin-bottom: 45px; }

input {  font-size: 18px;  padding: 10px 10px 10px 5px;  -webkit-appearance: none;  display: block;  background: transparent;  color: #696969;  width: 100%;  border: none;  border-radius: 0;  border-bottom: 1px solid #ddd;}

input:focus { outline: none; }

/* active */

input:focus ~ label, input.used ~ label {  top: -20px;  -webkit-transform: scale(.75);          transform: scale(.75); left: -12px;  color: #696969;}

/* active */
input:focus ~ .bar:before, input:focus ~ .bar:after { width: 50%; }

/* active */
input:focus ~ .highlight {  -webkit-animation: inputHighlighter 0.3s ease;          animation: inputHighlighter 0.3s ease;}

/* Animations */
@-webkit-keyframes inputHighlighter {
  from { background: #4a89dc; }
  to  { width: 0; background: transparent; }
}

@keyframes inputHighlighter {
  from { background: #4a89dc; }
  to  { width: 0; background: transparent; }
}

html, body{   background-size:cover;    margin:0;padding:0;    height:100%;}
.buttonui {   position: relative;    padding: 8px 45px;    line-height: 30px;    overflow: hidden;    border-width: 0;    outline: none;    border-radius: 2px;    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);    background-color: #f31a02;    color: #ecf0f1;    transition: background-color .3s;}
.buttonui:before {    content: "";    position: absolute;    top: 50%;    left: 50%;    display: block;    width: 0;    padding-top: 0;    border-radius: 100%;    background-color: rgba(236, 240, 241, .3);    -webkit-transform: translate(-50%, -50%);    -moz-transform: translate(-50%, -50%);    -ms-transform: translate(-50%, -50%);    -o-transform: translate(-50%, -50%);    transform: translate(-50%, -50%);}
.buttonui span  {    padding: 12px 24px;    font-size:16px;}
/* Ripples container */

.ripples {  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  overflow: hidden;  background: transparent;}
.ripplesCircle {  position: absolute;  top: 50%;  left: 50%;  -webkit-transform: translate(-50%, -50%);          transform: translate(-50%, -50%);  opacity: 0;  width: 0;  height: 0;  border-radius: 50%;  background: rgba(255, 255, 255, 0.25);}
.ripples.is-active .ripplesCircle {  -webkit-animation: ripples .4s ease-in;          animation: ripples .4s ease-in;}

/* Ripples animation */

/*react-slick*/
.slick-prev:before,
.slick-next:before {display: none;}
.slick-arrow {color: #000}
.slick-arrow:hover {color: #000}
@media screen and (max-width: 600px){
  .slick-initialized .slick-slide {display: none}
  .slick-slide.slick-active {display: block}
}
.slick-slide:not(.slick-center) div{-moz-transform:scale(0.92);-ms-transform:scale(0.92);-o-transform:scale(0.92);-webkit-transform:scale(0.92);opacity:0.7;transform:scale(0.92);}
.slick-list {-webkit-transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;-ms-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}


@-webkit-keyframes ripples {
  0% { opacity: 0; }

  25% { opacity: 1; }

  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}

@keyframes ripples {
  0% { opacity: 0; }

  25% { opacity: 1; }

  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}
form, .powered { opacity: 0.8; }
@media screen and (max-width: 460px){
  form, .powered { opacity: 0.8; }
}

#canvas-holder {
	width: 100%;
	margin-top: 50px;
	text-align: center;
}

#chartjs-tooltip {
	opacity: 1;
	position: absolute;
	background: rgba(0, 0, 0, .7);
	color: white;
	border-radius: 3px;
	-webkit-transition: all .1s ease;
	transition: all .1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 10px;
}

.shiny {
  color: #F5C21B;
  background: -webkit-gradient(linear, left top, left bottom, from(#F5C21B), to(#D17000));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2em;
  font-weight: 900;
  position: relative;
  text-transform: uppercase;
}

.shiny::before {
	background-position: -180px;
	-webkit-animation: flare 5s infinite;
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(65deg, transparent 20%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.3) 27%, transparent 27%, transparent 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  content: "Shiny";
  color: #FFF;
  display: block;
  padding-right: 140px;
  position: absolute;
}

.shiny::after {
  content: "Shiny";
  color: #FFF;
  display: block;
  position: absolute;
  text-shadow: 0 1px #6E4414, 0 2px #6E4414, 0 3px #6E4414, 0 4px #6E4414, 0 5px #6E4414, 0 6px #6E4414, 0 7px #6E4414, 0 8px #6E4414, 0 9px #6E4414, 0 10px #6E4414;
  top: 0;
  z-index: -1;
}

.inner-shiny::after, .inner-shiny::before {
		-webkit-animation: sparkle 5s infinite;
  -webkit-animation-timing-function: linear;
	background: #FFF;
  border-radius: 100%;
  box-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #FFF, 0 0 25px #FFF, 0 0 30px #FFF, 0 0 35px #FFF;
  content: "";
  display: block;
  height: 10px;
  opacity: 0.7;
  position: absolute;
  width: 10px;
}

.inner-shiny::before {
	-webkit-animation-delay: 0.2s;
  height: 7px;
  left: 0.12em;
  top: 0.8em;
  width: 7px;
}

.inner-shiny::after {
  top: 0.32em;
  right: -5px;
}

@-webkit-keyframes flare {
  0%   { background-position: -180px; }
  30%  { background-position: 500px; }
  100% { background-position: 500px; }
}

@-webkit-keyframes sparkle {
  0%   { opacity: 0; }
  30%  { opacity: 0; }
  40%  { opacity: 0.8; }
  60%  { opacity: 0; }
  100% { opacity: 0; }
}